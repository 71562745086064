import React, { useState, useEffect } from "react";
import "../styles/fullscreenPlayer.scss";
import playButton from "../images/play-button.svg";
import pauseButton from "../images/pause-button.svg";
import screenfull from "screenfull";
const PlayerNavigation = ({
  setPlaying,
  playing,
  setSlide,
  slide,
  videoDuration,
  player,
  setMuted,
  muted,
  setVolume,
  volume,
  fullscreen,
  setFullscreen,
  hideNavVolume = false,
  hideNavFullscreen = false,
}) => {
  const handleChange = function (event) {
    setSlide(event.target.value);
  };

  return (
    <div className="player__nav__bottom">
      <div className="player__nav__bottom__buttons__play-wrapper">
        <button
          style={{
            background: "none",
            border: "none",
            width: "25px",
          }}
          onClick={() => setPlaying(!playing)}
        >
          {playing ? <img src={pauseButton} /> : <img src={playButton} />}
        </button>
      </div>
      <div className="player__nav__bottom__buttons__slide-wrapper">
        <input
          type="range"
          min="1"
          max="100"
          value={slide}
          onChange={handleChange}
          onClick={() => {
            const newTime = videoDuration * (slide * 0.01);

            player.setCurrentTime(newTime);
          }}
          onMouseDown={() => {
            setPlaying(false);
          }}
          className="player__nav__slider"
          id="myRange"
        />
      </div>
      {!hideNavVolume ? (
        <div className="player__nav__bottom__buttons__mute-wrapper">
          <button
            onClick={() => {
              if (muted) {
                setMuted(false);
                setVolume(1);
              } else {
                setMuted(true);
                setVolume(0);
              }
            }}
            style={{ background: "none", border: "none" }}
          >
            <svg
              enableBackground="new 0 0 50 50"
              height="25px"
              id="Layer_1"
              version="1.1"
              viewBox="0 0 50 50"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
            >
              {!muted ? (
                <>
                  <rect fill="none" height="50" width="50" />
                  <path
                    d="M10,33H3  c-1.103,0-2-0.898-2-2V19c0-1.102,0.897-2,2-2h7"
                    fill="white"
                    stroke="white"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2.08"
                  />
                  <path
                    d="M9.604,32.43  C9.256,32.129,9,31.391,9,30.754V19.247c0-0.637,0.256-1.388,0.604-1.689L22.274,4.926C23.905,3.27,26,3.898,26,6.327v36.988  c0,2.614-1.896,3.604-3.785,1.686L9.604,32.43z"
                    fill="white"
                    stroke="white"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.9797"
                  />
                  <path
                    d="  M30.688,19.417C33.167,20.064,35,22.32,35,25s-1.833,4.936-4.312,5.584"
                    fill="none"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                  <path
                    d="  M34.92,13.142C39.136,15.417,42,19.873,42,25c0,5.111-2.85,9.557-7.045,11.835"
                    fill="none"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                  <path
                    d="  M38.498,7.82c6.276,3.167,10.579,9.668,10.579,17.18c0,7.512-4.303,14.014-10.579,17.18"
                    fill="none"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                  />
                </>
              ) : (
                <>
                  <line
                    fill="none"
                    stroke="white"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    x1="32"
                    x2="42"
                    y1="20"
                    y2="30"
                  />
                  <line
                    fill="none"
                    stroke="white"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                    x1="42"
                    x2="32"
                    y1="20"
                    y2="30"
                  />
                  <rect fill="none" height="50" width="50" />
                  <rect fill="none" height="50" width="50" />
                  <path
                    d="M10,33H3  c-1.103,0-2-0.898-2-2V19c0-1.102,0.897-2,2-2h7"
                    fill="white"
                    stroke="white"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="2.08"
                  />
                  <path
                    d="M9.604,32.43  C9.256,32.129,9,31.391,9,30.754V19.247c0-0.637,0.256-1.388,0.604-1.689L22.274,4.926C23.905,3.27,26,3.898,26,6.327v36.988  c0,2.614-1.896,3.604-3.785,1.686L9.604,32.43z"
                    fill="white"
                    stroke="white"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.9797"
                  />
                </>
              )}
            </svg>
          </button>
        </div>
      ) : (
        <></>
      )}
      {!hideNavFullscreen ? (
        <div className="player__nav__fullscreen-wrapper">
          {/* {false ? ( */}
          {screenfull.isEnabled ? (
            <button
              className={`player__nav__fullscreen fullscreen-${
                screenfull.isFullscreen ? "out" : "in"
              }`}
              onClick={() => {
                // setFullscreen(!fullscreen);
                const element = document.getElementById("player");
                screenfull.toggle(element, (el) => {});
              }}
            />
          ) : (
            <button
              className={`player__nav__fullscreen fullscreen-${
                fullscreen ? "out" : "in"
              }`}
              onClick={() => {
                setFullscreen((e) => {
                  return !e;
                });
              }}
            ></button>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PlayerNavigation;
